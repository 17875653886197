/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/*ForCheckbox para cortar popover asignaturas seleccionadas por el usuario*/
.alert-checkbox-label.sc-ion-alert-md,
.alert-checkbox-label.sc-ion-alert-ios {
  white-space: normal;
}
.alert-tappable.alert-checkbox {
  height: auto;
  contain: content;
}
.item.sc-ion-label-md-h, .item .sc-ion-label-md-h {
  // TODO: revisar qué estilo es para iOS, para cortar titulaciones seleccionadas por el usuario
  white-space: normal;
}
.split-pane-visible >.split-pane-side {
  min-width: 250px!important;
  max-width: 250px!important;
}
.custom-loader-recording {
  --spinner-color:  var(--ion-color-blue);
}
.custom-loader-events {
  --spinner-color:  var(--ion-color-raspberry);
}
[hidden] {
  display: none !important;
}