// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** Fonts **/
  --ion-font-family: 'Titilium';
  /** primary **/
  --ion-color-primary: #00533e;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #00533e;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #749f4c;
  --ion-color-secondary-rgb: 12, 209, 232;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #0bb8cc;
  --ion-color-secondary-tint: #24d6ea;

  /** tertiary **/
  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;

  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** blue **/
  --ion-color-blue: #5C6EB1;
  --ion-color-blue-rgb: 92, 110, 177;
  --ion-color-blue-contrast: #ffffff;
  --ion-color-blue-contrast-rgb: 255, 255, 255;
  --ion-color-blue-shade: #929ACB;
  --ion-color-blue-tint: #D4D6EB;
  --ion-color-bluem: #7683bd;

  /* apple */
  --ion-color-apple: #8aac5d;
  --ion-color-apple-contrast:#00533e;
  --ion-color-apple-contrast-rgb: 0, 83, 62;
  --ion-color-apple-shade: #8aac5d;
  --ion-color-apple-tint: #749f4c;

  /*raspberry*/
--ion-color-raspberry: #90214a;
--ion-color-raspberry-contrast: #ffffff;
--ion-color-raspberry-contrast-rgb: 255, 255, 255;
--ion-color-raspberry-shade: #8e4d60;
--ion-color-raspberry-tint: #dac7c7;
}
 /** uned **/
.ion-color-blue {
  --ion-color-base: var(--ion-color-blue);
  --ion-color-base-rgb: var(--ion-color-blue-rgb);
  --ion-color-contrast: var(--ion-color-blue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-blue-contrast-rgb);
  --ion-color-shade: var(--ion-color-blue-shade);
  --ion-color-tint: var(--ion-color-blue-tint);
}
.ion-color-bluem {
  --ion-color-base: var(--ion-color-bluem);
  --ion-color-base-rgb: var(--ion-color-blue-rgb);
  --ion-color-contrast: var(--ion-color-blue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-blue-contrast-rgb);
  --ion-color-shade: var(--ion-color-blue-shade);
  --ion-color-tint: var(--ion-color-blue-tint);
}
.ion-color-apple {
  --ion-color-base: #cddbb8;
  --ion-color-contrast:#00533e;
  --ion-color-contrast-rgb: 0, 83, 62;
  --ion-color-shade: #8aac5d;
  --ion-color-tint: #749f4c;
}
.ion-color-raspberry {
  --ion-color-base: var(--ion-color-raspberry);
  --ion-color-contrast: var(--ion-color-raspberry-contrast);
  --ion-color-contrast-rgb: var(--ion-color-raspberry-contrast-rgb);
  --ion-color-shade: var(--ion-color-raspberry-shade);
  --ion-color-tint: var(--ion-color-raspberry-tint);
}
.ion-color-raspberryl {
  --ion-color-base: #af848b;
  --ion-color-contrast:#ffffff;
  --ion-color-contrast-rgb:255, 255, 255;
  --ion-color-shade: #8e4d60;
  --ion-color-tint: #dac7c7;
}
.ion-color-strawberry {
  --ion-color-base: #da5268;
  --ion-color-contrast:#ffffff;
  --ion-color-contrast-rgb:255, 255, 255;
  --ion-color-shade: #da5268;
  --ion-color-tint: #efdbdf;
}
.ion-color-tangerine {
  --ion-color-base: #d76f47;
  --ion-color-contrast:#ffffff;
  --ion-color-contrast-rgb:255, 255, 255;
  --ion-color-shade: #dd8964;
  --ion-color-tint: #f0cfb9;
}

@font-face {
  font-family: "Titilium";
  font-style: normal;
  font-weight: normal;
  src: url('../assets/fonts/titilium web/TitilliumWeb-Regular.ttf');
}

@font-face {
  font-family: "Titilium";
  font-style: normal;
  font-weight: bold;
  src: url('../assets/fonts/titilium web/TitilliumWeb-Bold.ttf');
}

@font-face {
  font-family: "Titilium";
  font-style: italic;
  font-weight: normal;
  src: url('../assets/fonts/titilium web/TitilliumWeb-Italic.ttf');
}

@font-face {
  font-family: "Titilium";
  font-style: italic;
  font-weight: bold;
  src: url('../assets/fonts/titilium web/TitilliumWeb-BoldItalic.ttf');
}
